import React from 'react';
import { PageSectionItem, PageSectionText } from './PageSection';
import { FlexWrapper } from './Wrappers';
import DateFNSFormat from 'date-fns/format';
import { Rating } from './Rating';
import { Theming } from '../theming';
import { UserLabel } from './UserLabel';
import { AdaptedReview } from '../adapters/ratingsAdapters';
import { BaseButton } from './Buttons';
import { InfoText } from './Text';

export const Reviews = ({
	reviews,
	isFinalPage,
	pending,
	loadMoreResults,
}: {
	reviews: AdaptedReview[];
	isFinalPage: boolean;
	pending: boolean;
	loadMoreResults: () => void;
}) => {
	return (
		<>
			{reviews.map((review, index) => (
				<PageSectionItem key={index} borderTop={index > 0}>
					<FlexWrapper>
						<PageSectionText bold>
							{DateFNSFormat(new Date(review.date), 'MMMM yyyy')}
						</PageSectionText>
						<Rating rating={review.score} />
					</FlexWrapper>
					<PageSectionText fontSize={12} color={Theming.text.subTextColor}>
						{review.review}
					</PageSectionText>
					<UserLabel userType={review.reviewerType} name={review.name} />
				</PageSectionItem>
			))}
			<FlexWrapper margin="0 auto">
				{isFinalPage ? (
					<InfoText color={Theming.text.placeholderColor} margin={'20px auto'}>
						End of Results
					</InfoText>
				) : (
					<BaseButton pending={pending} text="Load more results..." onClick={loadMoreResults} />
				)}
			</FlexWrapper>
		</>
	);
};

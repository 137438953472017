import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { LabeledSelect } from '../../../../components/Select';
import {
	ColumnTitle,
	NoResultsMessage,
	PaginatedResultsLastRow,
	Table,
	TableItem,
	TableLink,
	TableLinkWrapper,
	TableRow,
} from '../../../../components/Table';
import { formatDate } from '../../../../utils/formatDate';
import LoadingSpinner from '../../../../components/Loader';
import { ErrorMessage } from '../../../../components/Text';
import IconManager, { IconType } from '../../../../components/IconManager';
import { PageHeader } from '../../../../components/Wrappers';
import { AdaptedFacilityInviteRequest } from '../../../../adapters/facilityInvitesAdapters';
import { FacilityInviteSortOptions } from '../../../../types/api/FacilityInviteRequests';
import { TabWithTableWrapper } from '../../../../components/Tabs';

type FacilityInviteListProps = {
	setSelectedInviteRequestId: (inviteRequest: AdaptedFacilityInviteRequest['id']) => void;
	inviteList: AdaptedFacilityInviteRequest[] | undefined;
	requestPending: boolean;
	requestError: string | null;
	setSortBy: Dispatch<SetStateAction<FacilityInviteSortOptions | null>>;
	getNextPage: () => void;
	showEndResultsMessage: boolean;
};

export const FacilityInviteList = ({
	setSelectedInviteRequestId,
	inviteList,
	requestError,
	requestPending,
	setSortBy,
	getNextPage,
	showEndResultsMessage,
}: FacilityInviteListProps) => {
	const [tableData, setTableData] = useState<AdaptedFacilityInviteRequest[] | undefined>(
		inviteList
	);

	useEffect(() => {
		//reset table data when changes are made to invite list
		setTableData(inviteList);
	}, [inviteList]);

	return (
		<TabWithTableWrapper>
			<PageHeader>
				<LabeledSelect
					label={'Sort by:'}
					options={
						tableData
							? [
									{ value: FacilityInviteSortOptions.date, label: 'Date' },
									{ value: FacilityInviteSortOptions.lastName, label: 'Last Name' },
									{ value: FacilityInviteSortOptions.firstName, label: 'First Name' },
									{ value: FacilityInviteSortOptions.facilityType, label: 'Facility Type' },
							  ]
							: []
					}
					onChange={newVal => setSortBy(newVal)}
				/>
			</PageHeader>
			<Table
				tableTitle={'Facility Invite Requests'}
				tableHeader={
					<>
						<ColumnTitle>Date</ColumnTitle>
						<ColumnTitle>Last Name</ColumnTitle>
						<ColumnTitle>First Name</ColumnTitle>
						<ColumnTitle>Facility Type</ColumnTitle>
						<ColumnTitle>{''}</ColumnTitle>
					</>
				}
				tableBody={
					<>
						{!tableData ? (
							<TableRow hideBorder>
								<TableItem colSpan={5}>
									{requestPending ? (
										<LoadingSpinner />
									) : (
										<ErrorMessage
											text={requestError ?? 'Error: Could not fetch results.'}
											textAlign={'center'}
											bold
										/>
									)}
								</TableItem>
							</TableRow>
						) : (
							<>
								{tableData.length === 0 ? (
									<NoResultsMessage colSpan={5} />
								) : (
									<>
										{tableData.map((item, index) => {
											return (
												<TableRow key={index}>
													<TableItem>{formatDate(item.date)}</TableItem>
													<TableItem textWrap>{item.lastName}</TableItem>
													<TableItem textWrap>{item.firstName}</TableItem>
													<TableItem textWrap>
														{item.facilityTypes.map((facilityType, index) =>
															index === 0 ? facilityType.name : `, ${facilityType.name}`
														)}
													</TableItem>
													<TableItem bold>
														<TableLinkWrapper onClick={() => setSelectedInviteRequestId(item.id)}>
															<TableLink>Review</TableLink>
															<IconManager
																type={IconType.CHEVRON_RIGHT}
																stroke={'#999'}
																size={14}
																strokeWidth={3}
															/>
														</TableLinkWrapper>
													</TableItem>
												</TableRow>
											);
										})}

										<PaginatedResultsLastRow
											isFinalPage={showEndResultsMessage}
											colSpan={5}
											pending={requestPending}
											onClickLoadMoreResults={getNextPage}
										/>
									</>
								)}
							</>
						)}
					</>
				}
			/>
		</TabWithTableWrapper>
	);
};

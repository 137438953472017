import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';

// NonAuth routes //
export const AppContainerNoNav = styled.div`
	width: 100%;
	margin: 0;
	padding: 0;
	height: 100vh;
`;

export const NonAuthPageContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	height: 100%;
	@media (max-width: ${Theming.breakpoints.smallScreen}px) {
		flex-direction: column;
	}
`;

export const NonAuthPageColumn = styled.div<{ width: number }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: ${({ width }) => width}%;
	height: 100%;
	vertical-align: center;
`;

export const NonAuthPageContentWrapper = styled.div`
	margin: 0 auto;
	vertical-align: center;
	width: 55%;
	text-align: center;
`;

// Auth routes //
export const MainContentContainer = styled.div`
	display: block;
	margin: 0;
	width: auto;
	background-color: transparent;
	box-sizing: border-box;
	padding: 0;
	flex: 1;
	overflow: hidden;
`;

export const PageWrapperNoTabs = styled.div<{ padding?: string }>`
	display: flex;
	flex-direction: column;
	height: 100%;
	overflow: auto;
	background-color: ${Theming.backgrounds.pageBackgroundNoTabs};
	padding: ${({ padding }) => padding ?? 0};
`;

export const PageWrapper = styled.div`
	width: 100%;
	padding: 25px;
`;

export const PageHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 25px;
`;

export const PageRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: stretch;
	justify-content: space-between;
	width: 100%;
	margin: 0;
	padding: 0;
`;

export const PageColumn = styled.div<{ width: number }>`
	display: inline-block;
	width: ${({ width }) => width}%;
	padding: 15px;
	min-width: 250px;
`;

export const FlexWrapper = styled.div<{ margin?: string; justifyContent?: string; width?: string }>`
	display: flex;
	width: ${({ width }) => width ?? '100%'};
	justify-content: ${({ justifyContent }) => justifyContent ?? 'flex-start'};
	align-items: center;
	padding: 0;
	margin: ${({ margin }) => margin ?? 0};
`;

// Wrappers for independently scrolling elements //
export const FlexScrollRow = styled.div<{ pending?: boolean }>`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: stretch;
	justify-content: space-between;
	padding: 0;
	margin: 0;
	height: 100%;
	width: 100%;
	:hover& {
		cursor: ${({ pending }) => (pending ? 'wait' : 'auto')};
	}
`;

export const FlexScrollColumn = styled.div<{ flex?: number; minWidth?: string; padding?: string }>`
	flex: ${({ flex }) => flex ?? 'default'};
	overflow-y: auto;
	overflow-x: hidden;
	padding: ${({ padding }) => padding ?? 0};
	margin: 0;
	min-width: ${({ minWidth }) => minWidth ?? '20%'};
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: flex-start;
`;

type ScrollWrapperProps = {
	children: ReactNode;
	minWidth?: string;
	padding?: string;
};

export const ScrollWrapper = ({ children, ...rest }: ScrollWrapperProps) => {
	return <StyledScrollWrapper {...rest}>{children}</StyledScrollWrapper>;
};

const StyledScrollWrapper = styled.div<{ padding?: string }>`
	overflow-x: hidden;
	overflow-y: auto;
	margin: 0;
	padding: ${({ padding }) => padding ?? 0};
	flex: 1;
	width: 100%;
`;

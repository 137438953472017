import React, { useEffect, useState } from 'react';
import { AdaptedFacilityLocation } from '../../../../adapters/locationsAdapters';
import { ErrorMessage, InfoText } from '../../../../components/Text';
import { FlexWrapper, PageColumn, PageRow } from '../../../../components/Wrappers';
import {
	MultiLineText,
	PageSection,
	PageSectionItem,
	SummaryItem,
} from '../../../../components/PageSection';
import { LocationKudos } from '../../../../components/Kudos';
import { Reviews } from '../../../../components/Reviews';
import LoadingSpinner from '../../../../components/Loader';
import { useAsync } from '../../../../hooks/useAsync';
import useSortAndPagination from '../../../../hooks/useSortFilterAndPagination';
import { LocationSortOptions } from '../../../../types/api/Locations';
import {
	AdaptedReviewList,
	adaptLocationReviewsFromApi,
} from '../../../../adapters/ratingsAdapters';
import { webApiManager } from '../../../../network/apiManager';
import { StatusDisplay } from '../../../../components/StatusDisplay';

type LocationDetailsProps = {
	selectedLocation: AdaptedFacilityLocation;
};

export const LocationOverviewTab = ({ selectedLocation }: LocationDetailsProps) => {
	const [reviews, setReviews] = useState<AdaptedReviewList | null>(null);

	useEffect(() => {
		//fetch reviews on load
		getReviews.execute();
	}, []);

	const onFetch = async (requestOptions: { page?: number } = {}) => {
		try {
			const request = { ...requestOptions, location_id: selectedLocation.locationId };
			const adaptedReviewList = adaptLocationReviewsFromApi(
				await webApiManager.Reviews.getReviews(request)
			);

			//if fetching next page, add to list and update pagination, otherwise replace
			setReviews(prevState => {
				if (adaptedReviewList.pagination.currentPage === prevState?.pagination.nextPage) {
					return {
						list: [...prevState.list, ...adaptedReviewList.list],
						pagination: adaptedReviewList.pagination,
					};
				} else {
					return adaptedReviewList;
				}
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getReviews = useAsync<void>(
		onFetch,
		'Error getting reviews. Please check your connection and try again.'
	);

	//handle pagination for reviews list
	const { getNextPage } = useSortAndPagination<LocationSortOptions>(reviews, getReviews);

	return (
		<PageRow>
			<PageColumn width={40}>
				<PageSection title={'Account Information'}>
					<PageSectionItem label={'Name'}>{selectedLocation.name}</PageSectionItem>
					<PageSectionItem label={'Status'}>
						<StatusDisplay statusType={selectedLocation.status} pause={selectedLocation.pause} />
					</PageSectionItem>
					<PageSectionItem label={'Address'} flex justifyContent={'flex-start'}>
						<MultiLineText>
							{selectedLocation.streetAddress}
							<br />
							{`${selectedLocation.city}, ${selectedLocation.state} ${selectedLocation.zipCode}`}
						</MultiLineText>
					</PageSectionItem>
					<PageSectionItem label={'Facility Type'}>
						{selectedLocation.facilityType?.name ?? 'N/A'}
					</PageSectionItem>
				</PageSection>
				<PageSection title={'Kudos'}>
					<LocationKudos kudos={selectedLocation.kudos} />
				</PageSection>
			</PageColumn>
			<PageColumn width={60}>
				<PageSection>
					<FlexWrapper>
						<SummaryItem
							label={'Completed Contracts'}
							amount={selectedLocation.completedContracts}
						/>
						<SummaryItem
							label={'Canceled Contracts'}
							amount={selectedLocation.canceledContracts}
							borderLeft
						/>
						<SummaryItem
							label={'Overall Rating'}
							amount={selectedLocation.rating.averageRating}
							borderLeft
						/>
					</FlexWrapper>
				</PageSection>
				<PageSection
					title={`Reviews (${selectedLocation.rating.numberReviews})`}
					maxHeight={'500px'}
				>
					{!reviews ? (
						<PageSectionItem>
							{getReviews.pending ? (
								<LoadingSpinner />
							) : (
								<ErrorMessage text={getReviews.error ?? 'Error getting reviews data.'} />
							)}
						</PageSectionItem>
					) : (
						<>
							{reviews.list.length === 0 ? (
								<InfoText>No reviews found.</InfoText>
							) : (
								<Reviews
									reviews={reviews.list}
									isFinalPage={reviews.pagination.isFinalPage}
									pending={getReviews.pending}
									loadMoreResults={getNextPage}
								/>
							)}
						</>
					)}
				</PageSection>
			</PageColumn>
		</PageRow>
	);
};

import React, { useEffect, useState } from 'react';
import { Tabs } from '../../components/Tabs';
import { AuthRouteNames, TabHash } from '../../navigation/routeNames';
import { useAsync } from '../../hooks/useAsync';
import { webApiManager } from '../../network/apiManager';
import useSortAndPagination from '../../hooks/useSortFilterAndPagination';
import styled from 'styled-components';
import LoadingSpinner from '../../components/Loader';
import {
	Table,
	TableRow,
	TableItem,
	ColumnTitle,
	PaginatedResultsLastRow,
} from '../../components/Table';
import { PageWrapper } from '../../components/Wrappers';
import { capitalizeString } from '../../utils/capitalizeString';
import formatDate from 'date-fns/format';
import { adaptDeletedUsersFromApi, AdaptedDeletedUsers } from '../../adapters/deletedUsersAdapters';
import { FormSelect } from '../../components/Select';
import { Theming } from '../../theming';
import { SearchInput } from '../../components/SearchInput';
import { debounce } from 'lodash';

const capitalize = s => capitalizeString(s.replace('_', ' '));

const USER_TYPE_OPTIONS = [
	{
		label: 'All Users',
		value: null,
	},
	{
		label: 'Contractors',
		value: 'contractor',
	},
	{
		label: 'Facility Users',
		value: 'facility_user',
	},
	{
		label: 'Location Users',
		value: 'location_user',
	},
];

export function DeletedUsers() {
	const [deletedUsers, setDeletedUsers] = useState<AdaptedDeletedUsers | null>(null);
	const [searchTerm, setSearchTerm] = useState<string | null>(null);
	const [searchUserType, setSearchUserType] = useState<string>('');

	useEffect(() => {
		getDeletedUsers.execute();
	}, []);

	useEffect(() => {
		getDeletedUsers.execute();
	}, [searchTerm, searchUserType]);

	const onFetch = async (requestOptions: { page?: number }) => {
		getDeletedUsers.clearError();

		try {
			const getDeletedUsersResponse = adaptDeletedUsersFromApi(
				await webApiManager.DeletedUsers.getDeletedUsers({
					...requestOptions,
					...(searchTerm ? { search_term: searchTerm } : {}),
					...(searchUserType ? { search_user_type: searchUserType } : {}),
				})
			);

			//if fetching next page, add to list and update pagination, otherwise replace
			setDeletedUsers(prevState => {
				if (getDeletedUsersResponse.pagination.currentPage === prevState?.pagination.nextPage) {
					return {
						list: [...prevState.list, ...getDeletedUsersResponse.list],
						pagination: getDeletedUsersResponse.pagination,
					};
				} else {
					return getDeletedUsersResponse;
				}
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getDeletedUsers = useAsync<void>(
		onFetch,
		'Error getting list of deleted users. Please check your connection and try again.'
	);

	const { getNextPage } = useSortAndPagination(deletedUsers, getDeletedUsers);

	return (
		<Tabs
			mainPath={AuthRouteNames.DeletedUsers}
			tabs={[
				{
					title: 'All',
					component: (
						<FullHeightFlexPageWrapper>
							{!deletedUsers ? (
								<LoadingSpinner />
							) : (
								<TableContainer>
									<OrganizationSelectContainer>
										<StyledFormSelect
											initialValue={null}
											options={USER_TYPE_OPTIONS}
											onChange={setSearchUserType}
											disabled={false}
										/>
										<SearchInput
											placeholder={'Search names...'}
											onSubmit={debounce(newVal => setSearchTerm(newVal), 500)}
											maxLength={100}
										/>
									</OrganizationSelectContainer>

									<StyledTable
										tableTitle="Deleted Users"
										isLoading={getDeletedUsers.pending}
										tableHeader={
											<>
												<ColumnTitle>User Name</ColumnTitle>
												<ColumnTitle>Email Address</ColumnTitle>
												<ColumnTitle>User Type</ColumnTitle>
												<ColumnTitle>Status</ColumnTitle>
												<ColumnTitle>Date</ColumnTitle>
											</>
										}
										tableBody={
											<>
												{deletedUsers.list.map(row => (
													<TableRow key={row.id}>
														<TableItem>{row.userName}</TableItem>
														<TableItem>{row.email}</TableItem>
														<TableItem>{capitalize(row.userType)}</TableItem>
														<TableItem>{capitalize(row.status)}</TableItem>
														<TableItem>{formatDate(row.date, 'MMMM dd, yyyy h:mm aa')}</TableItem>
													</TableRow>
												))}

												<PaginatedResultsLastRow
													isFinalPage={deletedUsers.pagination.isFinalPage}
													colSpan={5}
													pending={getDeletedUsers.pending}
													onClickLoadMoreResults={getNextPage}
												/>
											</>
										}
									></StyledTable>
									{getDeletedUsers.pending ? (
										<CenteredDiv>
											<LoadingSpinner />
										</CenteredDiv>
									) : null}
								</TableContainer>
							)}
						</FullHeightFlexPageWrapper>
					),
					hash: TabHash.all,
					hasTable: true,
				},
			]}
		/>
	);
}

const FullHeightFlexPageWrapper = styled(PageWrapper)`
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const TableContainer = styled.div`
	position: relative;
`;

const CenteredDiv = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
`;

const OrganizationSelectContainer = styled.div`
	display: flex;
	gap: 20px;
	margin-bottom: 20px;

	& > div {
		padding: 0;
		margin: 0;
		flex: 0;
	}

	& > div:last-child {
		min-width: 400px;
	}
`;

const StyledFormSelect = styled(FormSelect)`
	& > div {
		background-color: ${Theming.backgrounds.mainBackground};
		border: 1px solid ${Theming.buttons.primaryButtonColor};
	}
`;

const StyledTable = styled(Table)<{ isLoading?: boolean }>`
	min-width: 850px;
	opacity: ${({ isLoading }) => (isLoading ? 0.5 : 1)};
`;

import React, { useEffect, useState } from 'react';
import { Tabs } from '../../components/Tabs';
import { AuthRouteNames, TabHash } from '../../navigation/routeNames';
import { ContractList } from './sections/ContractList';
import { FlexScrollColumn, FlexScrollRow } from '../../components/Wrappers';
import { ContractFilters } from './sections/ContractFilters';
import { ContractMessages } from './sections/ContractMessages';
import { useAsync } from '../../hooks/useAsync';
import {
	ContractFilterOptions,
	ContractFilterType,
	ContractSortOptions,
} from '../../types/api/Contracts';
import { webApiManager } from '../../network/apiManager';
import {
	adaptContractsFromApi,
	AdaptedContract,
	AdaptedContractList,
} from '../../adapters/contractAdapters';
import useSortAndPagination from '../../hooks/useSortFilterAndPagination';

export function Contracts() {
	const [contracts, setContracts] = useState<AdaptedContractList | null>(null);
	const [selectedContract, setSelectedContract] = useState<AdaptedContract | null>(null);
	//to clear inputs/checkboxes when "clear filters" button is clicked
	const [shouldClearFilters, setShouldClearFilters] = useState<boolean>(false);

	useEffect(() => {
		//fetch contracts on load
		getContracts.execute();
	}, []);

	useEffect(() => {
		//reset value after checkboxes/inputs have been cleared
		if (shouldClearFilters) {
			setShouldClearFilters(false);
		}
	}, [shouldClearFilters]);

	const onFetch = async (requestOptions: { page?: number; sort_by?: ContractSortOptions }) => {
		try {
			const getContractsResponse = adaptContractsFromApi(
				await webApiManager.Contracts.getContracts(requestOptions)
			);
			//if fetching next page, add to list and update pagination, otherwise replace
			setContracts(prevState => {
				if (getContractsResponse.pagination.currentPage === prevState?.pagination.nextPage) {
					return {
						list: [...prevState.list, ...getContractsResponse.list],
						pagination: getContractsResponse.pagination,
					};
				} else {
					return getContractsResponse;
				}
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getContracts = useAsync<void>(
		onFetch,
		'Error getting contracts. Please check your connection and try again.'
	);

	const initialFilters: ContractFilterOptions = {
		[ContractFilterType.contractStatus]: [],
		[ContractFilterType.location]: '',
		[ContractFilterType.reported]: false,
		[ContractFilterType.contractId]: '',
		[ContractFilterType.paymentStatus]: [],
	};
	//handle sort, pagination, and filters on contract list
	//prettier-ignore
	const {
    setSortBy,
    getNextPage,
    filters,
    setFilters,
    resetFilters,
    refreshWithOptions,
  } = useSortAndPagination<ContractSortOptions, ContractFilterOptions>(
    contracts,
    getContracts,
    initialFilters
  );

	return (
		<Tabs
			mainPath={AuthRouteNames.Contracts}
			tabs={[
				{
					title: 'All',
					component: (
						<FlexScrollRow pending={getContracts.pending}>
							<FlexScrollColumn flex={0} minWidth={'260px'} padding={'25px 15px'}>
								<ContractFilters
									contracts={contracts}
									filters={filters}
									setFilters={setFilters}
									setSortBy={setSortBy}
									resetFilters={resetFilters}
									shouldClearFilters={shouldClearFilters}
									setShouldClearFilters={setShouldClearFilters}
								/>
							</FlexScrollColumn>
							<FlexScrollColumn flex={1} minWidth={'525px'} padding={'0'}>
								<ContractList
									selectedContract={selectedContract}
									setSelectedContract={setSelectedContract}
									contracts={contracts}
									getNextPage={getNextPage}
									pending={getContracts.pending}
									error={getContracts.error}
									shouldClearFilters={shouldClearFilters}
									setFilters={setFilters}
									refreshList={refreshWithOptions}
								/>
							</FlexScrollColumn>
							<FlexScrollColumn flex={0} minWidth={'260px'} padding={'0'}>
								<ContractMessages selectedContract={selectedContract} />
							</FlexScrollColumn>
						</FlexScrollRow>
					),
					hash: TabHash.all,
					hasTable: true,
				},
			]}
		/>
	);
}

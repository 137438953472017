import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Theming } from '../theming';
import { BaseButton } from './Buttons';
import { ErrorMessage } from './Text';

type TableProps = {
	className?: string;
	tableTitle?: string;
	tableHeader: ReactNode;
	tableBody: ReactNode;
	fixed?: boolean;
	useHorizontalPadding?: boolean;
};

export const Table = ({
	className,
	tableTitle,
	tableHeader,
	tableBody,
	fixed,
	useHorizontalPadding,
}: TableProps) => {
	return (
		<TableWrapper useHorizontalPadding={useHorizontalPadding} className={className}>
			{tableTitle ? <TableTitle>{tableTitle}</TableTitle> : null}
			<StyledTable fixed={fixed}>
				<TableHeader>
					<TableRow hideHover>{tableHeader}</TableRow>
				</TableHeader>
				<TableBody>{tableBody}</TableBody>
			</StyledTable>
		</TableWrapper>
	);
};

const TableWrapper = styled.div<{ useHorizontalPadding?: boolean }>`
	box-sizing: border-box;
	background-color: #fff;
	border-radius: 20px 20px 0 0;
	width: 100%;
	margin: 0 auto;
	padding: ${({ useHorizontalPadding }) => `15px ${useHorizontalPadding ? 20 : 0}px 40px`};
	flex: 1;
	overflow: auto;
`;

const TableTitle = styled.p`
	font-family: ${Theming.text.regularFont};
	font-size: 15px;
	color: ${Theming.text.titleColor};
	margin: 0;
	padding: 10px 25px;
	font-weight: bold;
`;

const StyledTable = styled.table<{ fixed?: boolean }>`
	background-color: #fff;
	border-radius: 20px 20px 0 0;
	width: 100%;
	margin: 0 auto;
	padding: 0;
	border-collapse: collapse;
	color: ${Theming.text.primaryTextColor};
	text-align: center;
	table-layout: ${({ fixed }) => (fixed ? 'fixed' : 'auto')};
`;

const TableHeader = styled.thead``;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr<{ hideBorder?: boolean; hideHover?: boolean }>`
	border-bottom: ${({ hideBorder }) =>
		hideBorder ? 'none' : `1px ${Theming.separatorColor} solid`};
	:hover& {
		background-color: ${({ hideHover }) =>
			hideHover ? 'transparent' : Theming.backgrounds.tableHover};
		cursor: default;
	}
`;

export const ColumnTitle = styled.th<{ textWrap?: boolean; textAlign?: string; padding?: string }>`
	padding: ${({ padding }) => padding || '20px'};
	margin: 5px 0;
	border-bottom: 2px #707070 solid;
	font-family: ${Theming.text.boldFont};
	font-size: 14px;
	white-space: ${({ textWrap }) => (textWrap ? 'normal' : 'nowrap')};
	font-weight: normal;
	text-align: ${({ textAlign }) => textAlign || 'inherit'};
`;

export const TableItem = styled.td<{
	bold?: boolean;
	color?: string;
	textWrap?: boolean;
	textAlign?: string;
	padding?: string;
}>`
	font-family: ${({ bold }) => (bold ? Theming.text.boldFont : Theming.text.regularFont)};
	text-align: ${({ textAlign }) => textAlign || 'inherit'};
	padding: ${({ padding }) => padding || '10px 15px'};
	font-size: 14px;
	color: ${({ color }) => color ?? 'inherit'};
	white-space: ${({ textWrap }) => (textWrap ? 'normal' : 'nowrap')};
`;

export const TableLinkWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	:hover& {
		cursor: pointer;
	}
`;

export const TableLink = styled.button`
	font-family: ${Theming.text.boldFont};
	color: ${Theming.text.secondaryLinkColor};
	font-size: 14px;
	text-decoration: none;
	padding: 0;
	margin: 0;
	border: none;
	background-color: transparent;
	:hover& {
		cursor: pointer;
	}
`;

export const PaginatedResultsLastRow = ({
	isFinalPage,
	pending,
	onClickLoadMoreResults,
	colSpan,
}) => {
	if (isFinalPage) {
		return <EndResultsMessage colSpan={colSpan} />;
	}

	return (
		<LoadMoreTableRow
			colSpan={colSpan}
			pending={pending}
			onClick={onClickLoadMoreResults}
		></LoadMoreTableRow>
	);
};

export const LoadMoreTableRow = ({ pending, onClick, colSpan }) => (
	<TableRow hideHover>
		<TableItem colSpan={colSpan}>
			<BaseButton pending={pending} text="Load more results..." onClick={onClick} />
		</TableItem>
	</TableRow>
);

export const EndResultsMessage = ({ colSpan }: { colSpan: number }) => {
	return (
		<TableRow hideBorder hideHover>
			<TableItem colSpan={colSpan} color={Theming.text.placeholderColor}>
				End of Results
			</TableItem>
		</TableRow>
	);
};

export const NoResultsMessage = ({ colSpan }: { colSpan: number }) => {
	return (
		<TableRow hideBorder hideHover>
			<TableItem colSpan={colSpan}>
				<ErrorMessage text={'No results found'} textAlign={'center'} />
			</TableItem>
		</TableRow>
	);
};

export const TableRowSpacer = (props: {
	height: number;
	backgroundColor: string;
	colSpan: number;
}) => {
	return (
		<tr style={{ height: props.height, backgroundColor: props.backgroundColor }}>
			<td colSpan={props.colSpan}> </td>
		</tr>
	);
};

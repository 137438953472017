import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	ColumnTitle,
	NoResultsMessage,
	PaginatedResultsLastRow,
	Table,
	TableItem,
	TableLink,
	TableLinkWrapper,
	TableRow,
} from '../../../../components/Table';
import { PageHeader } from '../../../../components/Wrappers';
import { LabeledSelect } from '../../../../components/Select';
import { BaseButton } from '../../../../components/Buttons';
import { TabWithTableWrapper } from '../../../../components/Tabs';
import { AdaptedFacility } from '../../../../adapters/facilityAdapters';
import { useAsync } from '../../../../hooks/useAsync';
import { AdaptedLocationList, adaptLocationsFromApi } from '../../../../adapters/locationsAdapters';
import { LocationSortOptions } from '../../../../types/api/Locations';
import { webApiManager } from '../../../../network/apiManager';
import useSortAndPagination from '../../../../hooks/useSortFilterAndPagination';
import { capitalizeString } from '../../../../utils/capitalizeString';
import LoadingSpinner from '../../../../components/Loader';
import { ErrorMessage } from '../../../../components/Text';
import IconManager, { IconType } from '../../../../components/IconManager';
import { SidePanel } from '../../../../components/SidePanel';
import { AddNewLocation } from '../locationTabs/components/AddNewLocation';
import { AuthRouteNames } from '../../../../navigation/routeNames';
import { StatusDisplay } from '../../../../components/StatusDisplay';

type FacilityLocationsProps = {
	selectedFacility: AdaptedFacility;
};

export const FacilityLocationsTab = ({ selectedFacility }: FacilityLocationsProps) => {
	const history = useHistory();

	const [locations, setLocations] = useState<AdaptedLocationList | null>(null);
	const [showAddLocation, setShowAddLocation] = useState<boolean>(false);

	useEffect(() => {
		//fetch locations on load
		getLocations.execute();
	}, []);

	useEffect(() => {
		//refresh list when sidebar closes to get any updates
		if (!!locations && !showAddLocation) {
			getLocations.execute();
		}
	}, [showAddLocation]);

	const onFetch = async (requestOptions: { page?: number; sort_by?: LocationSortOptions } = {}) => {
		getLocations.clearError();
		try {
			const getLocationsResponse = adaptLocationsFromApi(
				await webApiManager.Locations.getLocations({
					facilityId: selectedFacility.id,
					query: requestOptions,
				})
			);
			//if fetching next page, add to list and update pagination, otherwise replace
			setLocations(prevState => {
				if (getLocationsResponse.pagination.currentPage === prevState?.pagination.nextPage) {
					return {
						list: [...prevState.list, ...getLocationsResponse.list],
						pagination: getLocationsResponse.pagination,
					};
				} else {
					return getLocationsResponse;
				}
			});
		} catch (error) {
			console.error(error);
			throw error;
		}
	};

	const getLocations = useAsync(
		onFetch,
		'Error getting locations data. Please check your connection and try again.'
	);

	//handle sort and pagination for locations list
	const { setSortBy, getNextPage } = useSortAndPagination<LocationSortOptions>(
		locations,
		getLocations
	);

	return (
		<TabWithTableWrapper>
			<PageHeader>
				<LabeledSelect
					label={'Sort by:'}
					options={[
						{ value: LocationSortOptions.name, label: 'Name' },
						{ value: LocationSortOptions.state, label: 'State' },
						{ value: LocationSortOptions.city, label: 'City' },
						{ value: LocationSortOptions.zipCode, label: 'Zip Code' },
						{ value: LocationSortOptions.status, label: 'Status' },
					]}
					onChange={newVal => setSortBy(newVal)}
				/>
				<BaseButton
					text={'+ ADD LOCATION'}
					onClick={() => setShowAddLocation(true)}
					margin={'20px'}
				/>
			</PageHeader>
			<Table
				tableTitle={`${capitalizeString(selectedFacility.name)} Locations`}
				tableHeader={
					<>
						<ColumnTitle>Name</ColumnTitle>
						<ColumnTitle>State</ColumnTitle>
						<ColumnTitle>City</ColumnTitle>
						<ColumnTitle>Zip Code</ColumnTitle>
						<ColumnTitle>Administrator</ColumnTitle>
						<ColumnTitle>Status</ColumnTitle>
						<ColumnTitle>{''}</ColumnTitle>
					</>
				}
				tableBody={
					<>
						{!locations ? (
							<TableRow hideBorder hideHover>
								<TableItem colSpan={6}>
									{getLocations.pending ? (
										<LoadingSpinner />
									) : (
										<ErrorMessage
											text={getLocations.error ?? 'Error: Could not fetch results.'}
											textAlign={'center'}
											bold
										/>
									)}
								</TableItem>
							</TableRow>
						) : (
							<>
								{locations.list.length === 0 ? (
									<NoResultsMessage colSpan={7} />
								) : (
									<>
										{locations.list.map(location => {
											return (
												<TableRow key={location.locationId}>
													<TableItem textWrap>{location.name}</TableItem>
													<TableItem textWrap>{location.state}</TableItem>
													<TableItem textWrap>{location.city}</TableItem>
													<TableItem>{location.zipCode}</TableItem>
													<TableItem>{location.locationAdmin?.email ?? 'N/A'}</TableItem>
													<TableItem textWrap>
														<StatusDisplay statusType={location.status} pause={location.pause} />
													</TableItem>
													<TableItem bold textWrap>
														<TableLinkWrapper
															//navigate to details page for selected location
															onClick={() =>
																history.push(
																	`${AuthRouteNames.ManageFacilities}/${selectedFacility.id}/locations/${location.locationId}`
																)
															}
														>
															<TableLink>View Details</TableLink>
															<IconManager
																type={IconType.CHEVRON_RIGHT}
																stroke={'#999'}
																size={14}
																strokeWidth={3}
															/>
														</TableLinkWrapper>
													</TableItem>
												</TableRow>
											);
										})}

										<PaginatedResultsLastRow
											isFinalPage={locations.pagination.isFinalPage}
											colSpan={7}
											pending={getLocations.pending}
											onClickLoadMoreResults={getNextPage}
										/>
									</>
								)}
							</>
						)}
					</>
				}
			/>

			<SidePanel
				isOpen={showAddLocation}
				hide={() => setShowAddLocation(false)}
				closeButtonText={selectedFacility.name.toUpperCase()}
			>
				<AddNewLocation
					facilityId={selectedFacility.id}
					onSuccess={() => setShowAddLocation(false)}
				/>
			</SidePanel>
		</TabWithTableWrapper>
	);
};
